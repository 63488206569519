import React from 'react'
import MainHome from './home/MainHome'

function Home() {
    return (
        <div>
            <MainHome/>
        </div>
    )
}

export default Home