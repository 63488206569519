import React from 'react'

function MainHome() {
    return (
        <>
            {/* OffCanvas Search End */}
            <div className="offcanvas-overlay" />
            {/* Slider Start */}
            <div className="slider-area">
                <div className="hero-slider-wrapper">
                    {/* Single Slider  */}
                    <div
                        className="single-slide slider-height-1 bg-img d-flex"
                        data-bg-image="../images/slider-image/sample-1.jpg"
                    >
                        <div className="container align-self-center">
                            <div className="slider-content-1 slider-animated-1 text-left pl-60px">
                                <h1 className="animated color-black">
                                    Xbox One Pro <br />
                                    Wireless Controller
                                </h1>
                                <p className="animated color-gray">Revolution Pro Controller.</p>
                                <a href="shop-4-column.html" className="shop-btn animated">
                                    SHOP NOW
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Single Slider  */}
                    <div
                        className="single-slide slider-height-1 bg-img d-flex"
                        data-bg-image="../images/slider-image/sample-2.jpg"
                    >
                        <div className="container align-self-center">
                            <div className="slider-content-1 slider-animated-2 text-left pl-60px">
                                <h1 className="animated color-black">
                                    Bobovr Z4 Virtual <br />
                                    Reality 3D Glasses
                                </h1>
                                <p className="animated color-gray">
                                    Virtual reality through a new lens
                                </p>
                                <a href="shop-4-column.html" className="shop-btn animated">
                                    SHOP NOW
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Single Slider  */}
                    <div
                        className="single-slide slider-height-1 bg-img d-flex"
                        data-bg-image="../images/slider-image/sample-3.jpg"
                    >
                        <div className="container align-self-center">
                            <div className="slider-content-1 slider-animated-3 text-left pl-60px">
                                <h1 className="animated color-black">
                                    Portable Wireless <br />
                                    Bluetooth Speaker
                                </h1>
                                <p className="animated color-gray">With Colorful LED Light</p>
                                <a href="shop-4-column.html" className="shop-btn animated">
                                    SHOP NOW
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* Single Slider  */}
                </div>
            </div>
            {/* Slider End */}
            {/* Static Area Start */}
            <div className="static-area mtb-50px">
                <div className="container">
                    <div className="static-area-wrap">
                        <div className="row">
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-md-30px mb-lm-30px">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-1.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>Free Shipping</h4>
                                        <p>On all orders over $75.00</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-md-30px mb-lm-30px">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-2.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>Free Returns</h4>
                                        <p>Returns are free within 9 days</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 mb-sm-30px">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-3.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>100% Payment Secure</h4>
                                        <p>Your payment are safe with us.</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                            {/* Static Single Item Start */}
                            <div className="col-lg-3 col-xs-12 col-md-6 col-sm-6 ">
                                <div className="single-static">
                                    <img
                                        src="../images/icons/static-icons-4.png"
                                        alt=""
                                        className="img-responsive"
                                    />
                                    <div className="single-static-meta">
                                        <h4>Support 24/7</h4>
                                        <p>Contact us 24 hours a day</p>
                                    </div>
                                </div>
                            </div>
                            {/* Static Single Item End */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Static Area End */}
            {/* Feature Area Start */}
            <div className="feature-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Featured Products</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="feature-slider-wrapper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center mb-30px">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/4.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/5.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/6.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/7.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Madden by Steve Madden
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center mb-30px">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/10.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/11.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Wireless Stereo Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center mb-30px">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Naham WiFi HD 1080P
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/14.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/15.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Sony WH-CH700N
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center mb-30px">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/16.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/17.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Polk Audio T30 Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/18.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag"></ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center mb-30px">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/21.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag"></ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/4.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/5.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        TCL 49S5 49” 4K Ultra HD
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        {/* Feature Area End */}
                        <div className="col-lg-4 col-md-4 mt-lm-30px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Hot Deals</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="hot-deal-slider-wrapper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/4.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/5.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        TCL 49S5 49” 4K Ultra HD
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$21.51</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="availability-list in-stock">
                                                Availability: <span>300 In Stock</span>
                                            </div>
                                            <div className="clockdiv">
                                                <div data-countdown="2023/12/01" />
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/6.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/7.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Polk Audio T30 Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$21.51</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="availability-list in-stock">
                                                Availability: <span>300 In Stock</span>
                                            </div>
                                            <div className="clockdiv">
                                                <div data-countdown="2023/12/01" />
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner Area End */}
            {/* Banner Area Start */}
            <div className="banner-area mt-30px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/1.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner Area End */}
            {/* Best sell Area Start */}
            <div className="best-sell-area mt-20px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>Best Sellers</h2>
                            </div>
                        </div>
                    </div>
                    <div className="best-sell-area-wrapper slider-nav-style-1 slider-nav-style-4">
                        <div className="single-slider-item">
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/6.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/7.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>Graphic Corner</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                SoundBox Pro Portable
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>900 In Stock</span>
                                        </div>
                                        <p>
                                            Android 8.1 (Oreo), Hisilicon Kirin 710 (12 nm), Octa-core
                                            (4x2.2 GHz Cortex-A73 &amp; 4x1.7 GHz Cortex-A53), Mali-G51
                                            MP4
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">$29.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/8.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/9.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul className="product-flag">
                                        <li className="new">-12%</li>
                                    </ul>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>STUDIO DESIGN</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                Wireless Stereo Speaker
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>300 In Stock</span>
                                        </div>
                                        <p>
                                            Galileo, and QZSS, Barometric Altimeter, Optical Heart Sensor,
                                            Accelerometer And Gyroscope, Ion-X Strengthened Glass
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="old-price">$25.51</li>
                                                <li className="current-price">$21.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="single-slider-item">
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/15.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/16.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul className="product-flag">
                                        <li className="new">-12%</li>
                                    </ul>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>STUDIO DESIGN</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                Polk Audio T30 Speaker
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>1200 In Stock</span>
                                        </div>
                                        <p>
                                            Dimensions (W x H x D): TV without stand: 43.7" x 25.3" x 3",
                                            TV with stand: 43.7" x 27.7" x 8.5"
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="old-price">$21.51</li>
                                                <li className="current-price">$21.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/11.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/12.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>STUDIO DESIGN</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                Sony WH-CH700N
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>300 In Stock</span>
                                        </div>
                                        <p>
                                            Android 8.1 (Oreo), Hisilicon Kirin 710 (12 nm), Octa-core
                                            (4x2.2 GHz Cortex-A73 &amp; 4x1.7 GHz Cortex-A53), Mali-G51
                                            MP4
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">$21.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="single-slider-item">
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/6.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/7.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>Graphic Corner</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                SoundBox Pro Portable
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>900 In Stock</span>
                                        </div>
                                        <p>
                                            Android 8.1 (Oreo), Hisilicon Kirin 710 (12 nm), Octa-core
                                            (4x2.2 GHz Cortex-A73 &amp; 4x1.7 GHz Cortex-A53), Mali-G51
                                            MP4
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">$29.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/8.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/9.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul className="product-flag">
                                        <li className="new">-12%</li>
                                    </ul>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>STUDIO DESIGN</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                Wireless Stereo Speaker
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>300 In Stock</span>
                                        </div>
                                        <p>
                                            Galileo, and QZSS, Barometric Altimeter, Optical Heart Sensor,
                                            Accelerometer And Gyroscope, Ion-X Strengthened Glass
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="old-price">$25.51</li>
                                                <li className="current-price">$21.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="single-slider-item">
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/15.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/16.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <ul className="product-flag">
                                        <li className="new">-12%</li>
                                    </ul>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>STUDIO DESIGN</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                Polk Audio T30 Speaker
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>1200 In Stock</span>
                                        </div>
                                        <p>
                                            Dimensions (W x H x D): TV without stand: 43.7" x 25.3" x 3",
                                            TV with stand: 43.7" x 27.7" x 8.5"
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="old-price">$21.51</li>
                                                <li className="current-price">$21.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            {/* Single Item */}
                            <article className="list-product text-center">
                                <div className="product-inner">
                                    <div className="img-block">
                                        <a href="single-product.html" className="thumbnail">
                                            <img
                                                className="first-img"
                                                src="../images/product-image/11.jpg"
                                                alt=""
                                            />
                                            <img
                                                className="second-img"
                                                src="../images/product-image/12.jpg"
                                                alt=""
                                            />
                                        </a>
                                        <div className="add-to-link">
                                            <ul>
                                                <li>
                                                    <a
                                                        className="quick_view"
                                                        href="#"
                                                        data-link-action="quickview"
                                                        title="Quick view"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <i className="lnr lnr-magnifier" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html" title="Add to Wishlist">
                                                        <i className="lnr lnr-heart" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="compare.html" title="Add to compare">
                                                        <i className="lnr lnr-sync" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product-decs">
                                        <a className="inner-link" href="shop-4-column.html">
                                            <span>STUDIO DESIGN</span>
                                        </a>
                                        <h2>
                                            <a href="single-product.html" className="product-link">
                                                Sony WH-CH700N
                                            </a>
                                        </h2>
                                        <div className="availability-list in-stock">
                                            Availability: <span>300 In Stock</span>
                                        </div>
                                        <p>
                                            Android 8.1 (Oreo), Hisilicon Kirin 710 (12 nm), Octa-core
                                            (4x2.2 GHz Cortex-A73 &amp; 4x1.7 GHz Cortex-A53), Mali-G51
                                            MP4
                                        </p>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="current-price">$21.51</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
            {/* Best sell Area End */}
            {/* Banner Area Start */}
            <div className="banner-area mt-30px mb-20px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 ">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/2.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/3.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/4.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner Area End */}
            {/* Arrivals Area Start */}
            <div className="arrival-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2>New Arrivals</h2>
                                {/* Nav tabs */}
                                <ul className="nav nav-tabs sub-category">
                                    <li className="nav-item">
                                        <a
                                            className="nav-link active"
                                            data-bs-toggle="tab"
                                            href="#tab-1"
                                        >
                                            Home Audio
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-2">
                                            Headphones
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="tab" href="#tab-3">
                                            Cameras
                                        </a>
                                    </li>
                                </ul>
                                {/* Nav tabs */}
                            </div>
                        </div>
                    </div>
                    {/* tab content */}
                    <div className="tab-content">
                        {/* First-Tab */}
                        <div id="tab-1" className="tab-pane active fade">
                            {/* Arrivel slider start */}
                            <div className="arrival-slider-wrapper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/4.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/5.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Naham WiFi HD 1080P
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/16.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/17.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Polk Audio T30 Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/21.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag"></ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            {/* Arrivel slider end */}
                        </div>
                        {/* First-Tab */}
                        {/* Second-Tab */}
                        <div id="tab-2" className="tab-pane fade">
                            {/* Arrivel slider start */}
                            <div className="arrival-slider-wrapper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/4.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/5.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Naham WiFi HD 1080P
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/16.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/17.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Polk Audio T30 Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/21.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag"></ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            {/* Arrivel slider end */}
                        </div>
                        {/* Second-Tab */}
                        {/* Third-Tab */}
                        <div id="tab-3" className="tab-pane fade">
                            {/* Arrivel slider start */}
                            <div className="arrival-slider-wrapper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/4.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/5.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Naham WiFi HD 1080P
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/16.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/17.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>GRAPHIC CORNER</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Polk Audio T30 Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/21.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag"></ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                                <div className="add-to-link">
                                                    <ul>
                                                        <li>
                                                            <a
                                                                className="quick_view"
                                                                href="#"
                                                                data-link-action="quickview"
                                                                title="Quick view"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <i className="lnr lnr-magnifier" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="wishlist.html" title="Add to Wishlist">
                                                                <i className="lnr lnr-heart" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="compare.html" title="Add to compare">
                                                                <i className="lnr lnr-sync" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul className="product-flag">
                                                <li className="new">-12%</li>
                                            </ul>
                                            <div className="product-decs">
                                                <a className="inner-link" href="shop-4-column.html">
                                                    <span>STUDIO DESIGN</span>
                                                </a>
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="cart-btn">
                                                <a href="#" className="add-to-curt" title="Add to cart">
                                                    Add to cart
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            {/* Arrivel slider end */}
                        </div>
                        {/* Third-Tab */}
                    </div>
                    {/* tab content end*/}
                </div>
            </div>
            {/* Arrivals Area End */}
            {/* Banner Area Start */}
            <div className="banner-area mt-20px mb-20px ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/5.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/6.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="banner-wrapper">
                                <a href="shop-4-column.html">
                                    <img src="../images/banner-image/7.jpg" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Banner Area End */}
            {/* Catogery slider area Start*/}
            <div className="category-slider-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Home Audio</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="category-slider-wraper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/14.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/11.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/6.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/7.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/10.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Madden by Steve Madden Cale 6
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Wireless Stereo Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Headphones</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="category-slider-wraper slider-nav-style-1">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/6.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/7.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/10.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Madden by Steve Madden Cale 6
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Wireless Stereo Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/14.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/11.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h2>Television &amp; Video</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="category-slider-wraper slider-nav-style-1 ">
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/20.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Silicon Sleeping Earbuds
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/13.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/14.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Numkuda USB 2.0 Gamepad
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/11.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/12.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Edifier H840 Audiophile
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="slider-single-item">
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/6.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/7.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        SoundBox Pro Portable
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/10.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/19.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Madden by Steve Madden Cale 6
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    {/* Single Item */}
                                    <article className="list-product text-center">
                                        <div className="product-inner">
                                            <div className="img-block">
                                                <a href="single-product.html" className="thumbnail">
                                                    <img
                                                        className="first-img"
                                                        src="../images/product-image/8.jpg"
                                                        alt=""
                                                    />
                                                    <img
                                                        className="second-img"
                                                        src="../images/product-image/9.jpg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                            <div className="product-decs">
                                                <h2>
                                                    <a href="single-product.html" className="product-link">
                                                        Wireless Stereo Speaker
                                                    </a>
                                                </h2>
                                                <div className="pricing-meta">
                                                    <ul>
                                                        <li className="old-price">$23.90</li>
                                                        <li className="current-price">$21.51</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Catogery slider area End*/}
            {/* Brand area start */}
            <div className="brand-area mt-20px">
                <div className="container">
                    <div className="brand-slider">
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/1.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/2.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/3.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/4.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/5.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/1.png"
                                    alt=""
                                />
                            </a>
                        </div>
                        <div className="brand-slider-item">
                            <a href="#">
                                <img
                                    className=" img-responsive"
                                    src="../images/brand-logo/2.png"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* Brand area end */}
            {/* category Area Start */}
            <div className="popular-categories-area mt-50px mb-50px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                <h2 className="section-heading">Popular Categories</h2>
                            </div>
                        </div>
                    </div>
                    <div className="popular-category-slider-wrapper slider-nav-style-1 slider-nav-style-2 ">
                        {/* single-item */}
                        <div className="single-slider-item">
                            <div className="thumb-category">
                                <a href="single-product.html">
                                    <img
                                        src="../images/popular-image/1.jpg"
                                        alt="product-image.jpg"
                                    />
                                </a>
                            </div>
                            <div className="category-discript">
                                <h4>Phones &amp; Accessories</h4>
                                <ul>
                                    <li>
                                        <a href="#">Hot Categories</a>
                                    </li>
                                    <li>
                                        <a href="#">Outerwear &amp; Jackets</a>
                                    </li>
                                    <li>
                                        <a href="#">Weddings &amp; Events</a>
                                    </li>
                                    <li>
                                        <a href="#">Bottoms</a>
                                    </li>
                                    <li>
                                        <a href="#">Tops &amp; Sets</a>
                                    </li>
                                </ul>
                                <a href="shop-4-column.html" className="view-all-btn">
                                    View All
                                </a>
                            </div>
                        </div>
                        {/* single-item */}
                        <div className="single-slider-item">
                            <div className="thumb-category">
                                <a href="single-product.html">
                                    <img
                                        src="../images/popular-image/2.jpg"
                                        alt="product-image.jpg"
                                    />
                                </a>
                            </div>
                            <div className="category-discript">
                                <h4>Home Audio</h4>
                                <ul>
                                    <li>
                                        <a href="#">Skirts</a>
                                    </li>
                                    <li>
                                        <a href="#">Leggings</a>
                                    </li>
                                    <li>
                                        <a href="#">Jeans</a>
                                    </li>
                                    <li>
                                        <a href="#">Pants &amp; Capris</a>
                                    </li>
                                    <li>
                                        <a href="#">Shorts</a>
                                    </li>
                                </ul>
                                <a href="shop-4-column.html" className="view-all-btn">
                                    View All
                                </a>
                            </div>
                        </div>
                        {/* single-item */}
                        <div className="single-slider-item">
                            <div className="thumb-category">
                                <a href="single-product.html">
                                    <img
                                        src="../images/popular-image/3.jpg"
                                        alt="product-image.jpg"
                                    />
                                </a>
                            </div>
                            <div className="category-discript">
                                <h4>Audio &amp; Video</h4>
                                <ul>
                                    <li>
                                        <a href="#">Accessories &amp; Parts</a>
                                    </li>
                                    <li>
                                        <a href="#">Audio &amp; Video</a>
                                    </li>
                                    <li>
                                        <a href="#">Camera &amp; Photo</a>
                                    </li>
                                    <li>
                                        <a href="#">Portable Audio &amp; Video</a>
                                    </li>
                                    <li>
                                        <a href="#">Smart Electronics</a>
                                    </li>
                                </ul>
                                <a href="shop-4-column.html" className="view-all-btn">
                                    View All
                                </a>
                            </div>
                        </div>
                        {/* single-item */}
                        <div className="single-slider-item">
                            <div className="thumb-category">
                                <a href="single-product.html">
                                    <img
                                        src="../images/popular-image/4.jpg"
                                        alt="product-image.jpg"
                                    />
                                </a>
                            </div>
                            <div className="category-discript">
                                <h4>Cameras</h4>
                                <ul>
                                    <li>
                                        <a href="#">Televisions</a>
                                    </li>
                                    <li>
                                        <a href="#">TV Receivers</a>
                                    </li>
                                    <li>
                                        <a href="#">Projectors</a>
                                    </li>
                                    <li>
                                        <a href="#">Audio Amplifier Boards</a>
                                    </li>
                                    <li>
                                        <a href="#">TV Sticks</a>
                                    </li>
                                </ul>
                                <a href="shop-4-column.html" className="view-all-btn">
                                    View All
                                </a>
                            </div>
                        </div>
                        {/* single-item */}
                        <div className="single-slider-item">
                            <div className="thumb-category">
                                <a href="single-product.html">
                                    <img
                                        src="../images/popular-image/1.jpg"
                                        alt="product-image.jpg"
                                    />
                                </a>
                            </div>
                            <div className="category-discript">
                                <h4>Television &amp; Video</h4>
                                <ul>
                                    <li>
                                        <a href="#">Wearable Devices</a>
                                    </li>
                                    <li>
                                        <a href="#">Handheld Game Players</a>
                                    </li>
                                    <li>
                                        <a href="#">Game Controllers</a>
                                    </li>
                                    <li>
                                        <a href="#">Joysticks</a>
                                    </li>
                                    <li>
                                        <a href="#">Stickers</a>
                                    </li>
                                </ul>
                                <a href="shop-4-column.html" className="view-all-btn">
                                    View All
                                </a>
                            </div>
                        </div>
                        {/* single-item end*/}
                    </div>
                </div>
            </div>
            {/* category Area End */}
            {/* News letter area */}
            <div className="news-letter-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="title-newsletter">
                                <h2>Sign Up For Newsletters</h2>
                                <p className="des">
                                    Be the First to Know. Sign up for newsletter today !
                                </p>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div id="mc_embed_signup" className="subscribe-form">
                                <form
                                    id="mc-embedded-subscribe-form"
                                    className="validate"
                                    noValidate=""
                                    target="_blank"
                                    name="mc-embedded-subscribe-form"
                                    method="post"
                                    action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&id=05d85f18ef"
                                >
                                    <div id="mc_embed_signup_scroll" className="mc-form">
                                        <input
                                            className="email"
                                            type="email"
                                            required=""
                                            placeholder="Enter your email here.."
                                            name="EMAIL"
                                            defaultValue=""
                                        />
                                        <div className="mc-news" aria-hidden="true">
                                            <input
                                                type="text"
                                                defaultValue=""
                                                tabIndex={-1}
                                                name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef"
                                            />
                                        </div>
                                        <div className="clear">
                                            <input
                                                id="mc-embedded-subscribe"
                                                className="button"
                                                type="submit"
                                                name="subscribe"
                                                defaultValue="Sign Up"
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainHome