import React from 'react'

function MobileHeader() {
    return (
        <>
            <div className="mobile-header d-lg-none sticky-nav bg-white ptb-20px">
                <div className="container">
                    <div className="row align-items-center">
                        {/* Header Logo Start */}
                        <div className="col">
                            <div className="header-logo">
                                <a href="index.html">
                                    <img
                                        className="img-responsive"
                                        src="../images/logo/logo.png"
                                        alt="logo.jpg"
                                    />
                                </a>
                            </div>
                        </div>
                        {/* Header Logo End */}
                        {/* Header Tools Start */}
                        <div className="col-auto">
                            <div className="header-tools justify-content-end">
                                <div className="cart-info d-flex align-self-center">
                                    <a href="#offcanvas-wishlist" className="heart offcanvas-toggle">
                                        <i className="lnr lnr-heart" />
                                        <span>Wishlist</span>
                                    </a>
                                    <a href="#offcanvas-cart" className="bag offcanvas-toggle">
                                        <i className="lnr lnr-cart" />
                                        <span>My Cart</span>
                                    </a>
                                </div>
                                <div className="mobile-menu-toggle">
                                    <a href="#offcanvas-mobile-menu" className="offcanvas-toggle">
                                        <svg viewBox="0 0 800 600">
                                            <path
                                                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                id="top"
                                            />
                                            <path d="M300,320 L540,320" id="middle" />
                                            <path
                                                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                id="bottom"
                                                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Header Tools End */}
                    </div>
                </div>
            </div>
            {/* Search Category Start */}
            <div className="mobile-search-area d-lg-none mb-15px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="search-element media-body">
                                <form className="d-flex" action="#">
                                    <div className="search-category">
                                        <select>
                                            <option value={0}>All categories</option>
                                            <option value={12}>Laptop</option>
                                            <option value={13}>- - Hot Categories</option>
                                            <option value={19}>- - - - Dresses</option>
                                            <option value={20}>- - - - Jackets &amp; Coats</option>
                                            <option value={21}>- - - - Sweaters</option>
                                            <option value={22}>- - - - Jeans</option>
                                            <option value={23}>- - - - Blouses &amp; Shirts</option>
                                            <option value={14}>- - Outerwear &amp; Jackets</option>
                                            <option value={24}>- - - - Basic Jackets</option>
                                            <option value={25}>- - - - Real Fur</option>
                                            <option value={26}>- - - - Down Coats</option>
                                            <option value={27}>- - - - Blazers</option>
                                            <option value={28}>- - - - Trench Coats</option>
                                            <option value={15}>- - Weddings &amp; Events</option>
                                            <option value={29}>- - - - Wedding Dresses</option>
                                            <option value={30}>- - - - Evening Dresses</option>
                                            <option value={31}>- - - - Prom Dresses</option>
                                            <option value={32}>- - - - Bridesmaid Dresses</option>
                                            <option value={33}>- - - - Wedding Accessories</option>
                                            <option value={16}>- - Bottoms</option>
                                            <option value={34}>- - - - Skirts</option>
                                            <option value={35}>- - - - Leggings</option>
                                            <option value={36}>- - - - Pants &amp; Capris</option>
                                            <option value={37}>- - - - Wide Leg Pants</option>
                                            <option value={38}>- - - - Shorts</option>
                                            <option value={17}>- - Tops &amp; Sets</option>
                                            <option value={39}>- - - - Tank Tops</option>
                                            <option value={40}>- - - - Suits &amp; Sets</option>
                                            <option value={41}>- - - - Jumpsuits</option>
                                            <option value={42}>- - - - Rompers</option>
                                            <option value={43}>- - - - Sleep &amp; Lounge</option>
                                            <option value={18}>- - Accessories</option>
                                            <option value={44}>- - - - Eyewear &amp; Accessories</option>
                                            <option value={45}>- - - - Hats &amp; Caps</option>
                                            <option value={46}>- - - - Belts &amp; Cummerbunds</option>
                                            <option value={47}>- - - - Scarves &amp; Wraps</option>
                                            <option value={48}>- - - - Gloves &amp; Mittens</option>
                                            <option value={49}>Computer</option>
                                            <option value={50}>- - Bottoms</option>
                                            <option value={53}>- - - - Skirts</option>
                                            <option value={54}>- - - - Leggings</option>
                                            <option value={55}>- - - - Jeans</option>
                                            <option value={56}>- - - - Pants &amp; Capris</option>
                                            <option value={57}>- - - - Shorts</option>
                                            <option value={51}>- - Outerwear &amp; Jackets</option>
                                            <option value={58}>- - - - Trench</option>
                                            <option value={59}>- - - - Genuine Leather</option>
                                            <option value={60}>- - - - Parkas</option>
                                            <option value={61}>- - - - Down Jackets</option>
                                            <option value={62}>- - - - Wool &amp; Blends</option>
                                            <option value={52}>- - Underwear &amp; Loungewear</option>
                                            <option value={63}>- - - - Boxers</option>
                                            <option value={64}>- - - - Briefs</option>
                                            <option value={65}>- - - - Long Johns</option>
                                            <option value={66}>- - - - Men's Sleep &amp; Lounge</option>
                                            <option value={67}>- - - - Pajama Sets</option>
                                            <option value={68}>Smartphone</option>
                                            <option value={69}>- - Accessories &amp; Parts</option>
                                            <option value={75}>- - - - Cables &amp; Adapters</option>
                                            <option value={76}>- - - - Batteries</option>
                                            <option value={77}>- - - - Chargers</option>
                                            <option value={78}>- - - - Bags &amp; Cases</option>
                                            <option value={79}>- - - - Electronic Cigarettes</option>
                                            <option value={70}>- - Audio &amp; Video</option>
                                            <option value={80}>- - - - Televisions</option>
                                            <option value={81}>- - - - TV Receivers</option>
                                            <option value={82}>- - - - Projectors</option>
                                            <option value={83}>- - - - Audio Amplifier Boards</option>
                                            <option value={84}>- - - - TV Sticks</option>
                                            <option value={71}>- - Camera &amp; Photo</option>
                                            <option value={85}>- - - - Digital Cameras</option>
                                            <option value={86}>- - - - Camcorders</option>
                                            <option value={87}>- - - - Camera Drones</option>
                                            <option value={88}>- - - - Action Cameras</option>
                                            <option value={89}>- - - - Photo Studio Supplies</option>
                                            <option value={72}>- - Portable Audio &amp; Video</option>
                                            <option value={90}>- - - - Headphones</option>
                                            <option value={91}>- - - - Speakers</option>
                                            <option value={92}>- - - - MP3 Players</option>
                                            <option value={93}>- - - - VR/AR Devices</option>
                                            <option value={94}>- - - - Microphones</option>
                                            <option value={73}>- - Smart Electronics</option>
                                            <option value={95}>- - - - Wearable Devices</option>
                                            <option value={96}>- - - - Smart Home Appliances</option>
                                            <option value={97}>- - - - Smart Remote Controls</option>
                                            <option value={98}>- - - - Smart Watches</option>
                                            <option value={99}>- - - - Smart Wristbands</option>
                                            <option value={74}>- - Video Games</option>
                                            <option value={100}>- - - - Handheld Game Players</option>
                                            <option value={101}>- - - - Game Controllers</option>
                                            <option value={102}>- - - - Joysticks</option>
                                            <option value={103}>- - - - Stickers</option>
                                            <option value={104}>Game Consoles</option>
                                            <option value={105}>Mp3 &amp; headphone</option>
                                            <option value={106}>Tv &amp; Video</option>
                                            <option value={107}>Watches</option>
                                            <option value={108}>Washing Machine</option>
                                            <option value={109}>Camera</option>
                                            <option value={110}>Audio &amp; Theater</option>
                                            <option value={111}>Accessories</option>
                                            <option value={112}>Games &amp; Consoles</option>
                                        </select>
                                    </div>
                                    <input type="text" placeholder="Enter your search key ... " />
                                    <button>
                                        <i className="lnr lnr-magnifier" />
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Search Category End */}
            <div className="mobile-category-nav d-lg-none mb-15px">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/*=======  category menu  =======*/}
                            <div className="hero-side-category">
                                {/* Category Toggle Wrap */}
                                <div className="category-toggle-wrap">
                                    {/* Category Toggle */}
                                    <button className="category-toggle">
                                        <i className="fa fa-bars" /> All Categories
                                    </button>
                                </div>
                                {/* Category Menu */}
                                <nav className="category-menu">
                                    <ul>
                                        <li>
                                            <a href="#">Televisions</a>
                                        </li>
                                        <li className="menu-item-has-children menu-item-has-children-1">
                                            <a href="#">
                                                Accessories &amp; Parts
                                                <i className="ion-ios-arrow-down" />
                                            </a>
                                            {/* category submenu */}
                                            <ul className="category-mega-menu category-mega-menu-1">
                                                <li>
                                                    <a href="#">Cables &amp; Adapters</a>
                                                </li>
                                                <li>
                                                    <a href="#">Batteries</a>
                                                </li>
                                                <li>
                                                    <a href="#">Chargers</a>
                                                </li>
                                                <li>
                                                    <a href="#">Bags &amp; Cases</a>
                                                </li>
                                                <li>
                                                    <a href="#">Electronic Cigarettes</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children menu-item-has-children-2">
                                            <a href="#">
                                                Camera &amp; Photo
                                                <i className="ion-ios-arrow-down" />
                                            </a>
                                            {/* category submenu */}
                                            <ul className="category-mega-menu category-mega-menu-2">
                                                <li>
                                                    <a href="#">Digital Cameras</a>
                                                </li>
                                                <li>
                                                    <a href="#">Camcorders</a>
                                                </li>
                                                <li>
                                                    <a href="#">Camera Drones</a>
                                                </li>
                                                <li>
                                                    <a href="#">Action Cameras</a>
                                                </li>
                                                <li>
                                                    <a href="#">Photo Studio Supplies</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children menu-item-has-children-3">
                                            <a href="#">
                                                Smart Electronics <i className="ion-ios-arrow-down" />
                                            </a>
                                            {/* category submenu */}
                                            <ul className="category-mega-menu category-mega-menu-3">
                                                <li>
                                                    <a href="#">Wearable Devices</a>
                                                </li>
                                                <li>
                                                    <a href="#">Smart Home Appliances</a>
                                                </li>
                                                <li>
                                                    <a href="#">Smart Remote Controls</a>
                                                </li>
                                                <li>
                                                    <a href="#">Smart Watches</a>
                                                </li>
                                                <li>
                                                    <a href="#">Smart Wristbands</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children menu-item-has-children-4">
                                            <a href="#">
                                                Audio &amp; Video <i className="ion-ios-arrow-down" />
                                            </a>
                                            {/* category submenu */}
                                            <ul className="category-mega-menu category-mega-menu-4">
                                                <li>
                                                    <a href="#">Televisions</a>
                                                </li>
                                                <li>
                                                    <a href="#">TV Receivers</a>
                                                </li>
                                                <li>
                                                    <a href="#">Projectors</a>
                                                </li>
                                                <li>
                                                    <a href="#">Audio Amplifier Boards</a>
                                                </li>
                                                <li>
                                                    <a href="#">TV Sticks</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children menu-item-has-children-6">
                                            <a href="#">
                                                Video Game <i className="ion-ios-arrow-down" />
                                            </a>
                                            {/* category submenu */}
                                            <ul className="category-mega-menu category-mega-menu-6">
                                                <li>
                                                    <a href="#">Handheld Game Players</a>
                                                </li>
                                                <li>
                                                    <a href="#">Game Controllers</a>
                                                </li>
                                                <li>
                                                    <a href="#">Joysticks</a>
                                                </li>
                                                <li>
                                                    <a href="#">Stickers</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">Digital Cameras</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">Headphones</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Wearable Devices</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Smart Watches</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Game Controllers</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Smart Home Appliances</a>
                                        </li>
                                        <li className="hidden">
                                            <a href="#">Projectors</a>
                                        </li>
                                        <li>
                                            <a href="#" id="more-btn">
                                                <i className="ion-ios-plus-empty" aria-hidden="true" /> More
                                                Categories
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            {/*=======  End of category menu =======*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Header Section End */}
            {/* OffCanvas Wishlist Start */}
            <div id="offcanvas-wishlist" className="offcanvas offcanvas-wishlist">
                <div className="inner">
                    <div className="head">
                        <span className="title">Wishlist</span>
                        <button className="offcanvas-close">×</button>
                    </div>
                    <div className="body customScroll">
                        <ul className="minicart-product-list">
                            <li>
                                <a href="single-product.html" className="image">
                                    <img
                                        src="../images/product-image/1.jpg"
                                        alt="Cart product Image"
                                    />
                                </a>
                                <div className="content">
                                    <a href="single-product.html" className="title">
                                        Walnut Cutting Board
                                    </a>
                                    <span className="quantity-price">
                                        1 x <span className="amount">$100.00</span>
                                    </span>
                                    <a href="#" className="remove">
                                        ×
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a href="single-product.html" className="image">
                                    <img
                                        src="../images/product-image/2.jpg"
                                        alt="Cart product Image"
                                    />
                                </a>
                                <div className="content">
                                    <a href="single-product.html" className="title">
                                        Lucky Wooden Elephant
                                    </a>
                                    <span className="quantity-price">
                                        1 x <span className="amount">$35.00</span>
                                    </span>
                                    <a href="#" className="remove">
                                        ×
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a href="single-product.html" className="image">
                                    <img
                                        src="../images/product-image/3.jpg"
                                        alt="Cart product Image"
                                    />
                                </a>
                                <div className="content">
                                    <a href="single-product.html" className="title">
                                        Fish Cut Out Set
                                    </a>
                                    <span className="quantity-price">
                                        1 x <span className="amount">$9.00</span>
                                    </span>
                                    <a href="#" className="remove">
                                        ×
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="foot">
                        <div className="buttons">
                            <a
                                href="wishlist.html"
                                className="btn btn-dark btn-hover-primary mt-30px"
                            >
                                view wishlist
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* OffCanvas Wishlist End */}
            {/* OffCanvas Cart Start */}
            <div id="offcanvas-cart" className="offcanvas offcanvas-cart">
                <div className="inner">
                    <div className="head">
                        <span className="title">Cart</span>
                        <button className="offcanvas-close">×</button>
                    </div>
                    <div className="body customScroll">
                        <ul className="minicart-product-list">
                            <li>
                                <a href="single-product.html" className="image">
                                    <img
                                        src="../images/product-image/1.jpg"
                                        alt="Cart product Image"
                                    />
                                </a>
                                <div className="content">
                                    <a href="single-product.html" className="title">
                                        Walnut Cutting Board
                                    </a>
                                    <span className="quantity-price">
                                        1 x <span className="amount">$100.00</span>
                                    </span>
                                    <a href="#" className="remove">
                                        ×
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a href="single-product.html" className="image">
                                    <img
                                        src="../images/product-image/2.jpg"
                                        alt="Cart product Image"
                                    />
                                </a>
                                <div className="content">
                                    <a href="single-product.html" className="title">
                                        Lucky Wooden Elephant
                                    </a>
                                    <span className="quantity-price">
                                        1 x <span className="amount">$35.00</span>
                                    </span>
                                    <a href="#" className="remove">
                                        ×
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a href="single-product.html" className="image">
                                    <img
                                        src="../images/product-image/3.jpg"
                                        alt="Cart product Image"
                                    />
                                </a>
                                <div className="content">
                                    <a href="single-product.html" className="title">
                                        Fish Cut Out Set
                                    </a>
                                    <span className="quantity-price">
                                        1 x <span className="amount">$9.00</span>
                                    </span>
                                    <a href="#" className="remove">
                                        ×
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="foot">
                        <div className="sub-total">
                            <strong>Subtotal :</strong>
                            <span className="amount">$144.00</span>
                        </div>
                        <div className="buttons">
                            <a
                                href="cart.html"
                                className="btn btn-dark btn-hover-primary mb-30px"
                            >
                                view cart
                            </a>
                            <a href="checkout.html" className="btn btn-outline-dark current-btn">
                                checkout
                            </a>
                        </div>
                        <p className="minicart-message">
                            Free Shipping on All Orders Over $100!
                        </p>
                    </div>
                </div>
            </div>
            {/* OffCanvas Cart End */}
            {/* OffCanvas Search Start */}
            <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
                <div className="inner customScroll">
                    <div className="head">
                        <span className="title">&nbsp;</span>
                        <button className="offcanvas-close">×</button>
                    </div>
                    <div className="offcanvas-menu-search-form">
                        <form action="#">
                            <input type="text" placeholder="Search..." />
                            <button>
                                <i className="lnr lnr-magnifier" />
                            </button>
                        </form>
                    </div>
                    <div className="offcanvas-menu">
                        <ul>
                            <li>
                                <a href="#">
                                    <span className="menu-text">Home</span>
                                </a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="index.html">
                                            <span className="menu-text">Home 1</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="index-2.html">
                                            <span className="menu-text">Home 2</span>
                                        </a>
                                    </li>
                                    <li>
                                        {" "}
                                        <a href="index-3.html">
                                            <span className="menu-text">Home 3</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="index-4.html">
                                            <span className="menu-text">Home 4</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">
                                    <span className="menu-text">Shop</span>
                                </a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Shop Grid</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="shop-3-column.html">Shop Grid 3 Column</a>
                                            </li>
                                            <li>
                                                <a href="shop-4-column.html">Shop Grid 4 Column</a>
                                            </li>
                                            <li>
                                                <a href="shop-left-sidebar.html">Shop Grid Left Sidebar</a>
                                            </li>
                                            <li>
                                                <a href="shop-right-sidebar.html">
                                                    Shop Grid Right Sidebar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Shop List</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="shop-list.html">Shop List</a>
                                            </li>
                                            <li>
                                                <a href="shop-list-left-sidebar.html">
                                                    Shop List Left Sidebar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="shop-list-right-sidebar.html">
                                                    Shop List Right Sidebar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Shop Single</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="single-product.html">Shop Single</a>
                                            </li>
                                            <li>
                                                <a href="single-product-variable.html">Shop Variable</a>
                                            </li>
                                            <li>
                                                <a href="single-product-affiliate.html">Shop Affiliate</a>
                                            </li>
                                            <li>
                                                <a href="single-product-group.html">Shop Group</a>
                                            </li>
                                            <li>
                                                <a href="single-product-tabstyle-2.html">Shop Tab 2</a>
                                            </li>
                                            <li>
                                                <a href="single-product-tabstyle-3.html">Shop Tab 3</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Shop Single</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="single-product-slider.html">Shop Slider</a>
                                            </li>
                                            <li>
                                                <a href="single-product-gallery-left.html">
                                                    Shop Gallery Left
                                                </a>
                                            </li>
                                            <li>
                                                <a href="single-product-gallery-right.html">
                                                    Shop Gallery Right
                                                </a>
                                            </li>
                                            <li>
                                                <a href="single-product-sticky-left.html">
                                                    Shop Sticky Left
                                                </a>
                                            </li>
                                            <li>
                                                <a href="single-product-sticky-right.html">
                                                    Shop Sticky Right
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">
                                    <span className="menu-text">Pages</span>
                                </a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="about.html">About Page</a>
                                    </li>
                                    <li>
                                        <a href="cart.html">Cart Page</a>
                                    </li>
                                    <li>
                                        <a href="checkout.html">Checkout Page</a>
                                    </li>
                                    <li>
                                        <a href="compare.html">Compare Page</a>
                                    </li>
                                    <li>
                                        <a href="login.html">Login &amp; Register Page</a>
                                    </li>
                                    <li>
                                        <a href="my-account.html">Account Page</a>
                                    </li>
                                    <li>
                                        <a href="empty-cart.html">Empty Cart Page</a>
                                    </li>
                                    <li>
                                        <a href="404.html">404 Page</a>
                                    </li>
                                    <li>
                                        <a href="wishlist.html">Wishlist Page</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">
                                    <span className="menu-text">Blog</span>
                                </a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Blog Grid</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="blog-grid-left-sidebar.html">
                                                    Blog Grid Left Sidebar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="blog-grid-right-sidebar.html">
                                                    Blog Grid Right Sidebar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Blog List</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="blog-list-left-sidebar.html">
                                                    Blog List Left Sidebar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="blog-list-right-sidebar.html">
                                                    Blog List Right Sidebar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <span className="menu-text">Blog Single</span>
                                        </a>
                                        <ul className="sub-menu">
                                            <li>
                                                <a href="blog-single-left-sidebar.html">
                                                    Blog Single Left Sidebar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="blog-single-right-sidebar.html">
                                                    Blog Single Right Sidbar
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">
                                    <span className="menu-text">Custom Menu</span>
                                </a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="shop-4-column.html">Women Is Clothes &amp; Fashion</a>
                                    </li>
                                    <li>
                                        <a href="shop-4-column.html">Simple Style</a>
                                    </li>
                                    <li>
                                        <a href="shop-4-column.html">Easy Style</a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="contact.html">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                    {/* OffCanvas Menu End */}
                    <div className="offcanvas-social mt-30px">
                        <ul>
                            <li>
                                <a href="#">
                                    <i className="ion-social-facebook" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="ion-social-twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="ion-social-google" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="ion-social-youtube" />
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="ion-social-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileHeader