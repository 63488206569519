import React from 'react'

function Footer() {
    return (
        <>
            <div className="footer-area">
                <div className="footer-container">
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                                    <div className="single-wedge">
                                        <div className="footer-logo">
                                            <a href="index.html">
                                                <img
                                                    className="img-responsive"
                                                    src="../images/logo/logo.png"
                                                    alt="logo.jpg"
                                                />
                                            </a>
                                        </div>
                                        <p className="text-infor">
                                            We are a team of designers and developers that create high
                                            quality HTML template
                                        </p>
                                        <div className="need_help">
                                            <p className="add">
                                                <span className="address">Address:</span> 4710-4890
                                                Breckinridge St, Fayetteville
                                            </p>
                                            <p className="mail">
                                                <span className="email">Email:</span>{" "}
                                                <a href="mailto:support@hasthemes.com">
                                                    support@hasthemes.com
                                                </a>
                                            </p>
                                            <p className="phone">
                                                <span className="call us">Call Us:</span>{" "}
                                                <a href="tel:(+800)123456789"> (+800)123456789</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-2 col-sm-6 mb-md-30px mb-lm-30px">
                                    <div className="single-wedge">
                                        <h4 className="footer-herading">Information</h4>
                                        <div className="footer-links">
                                            <ul>
                                                <li>
                                                    <a href="#">Delivery</a>
                                                </li>
                                                <li>
                                                    <a href="about.html">About Us</a>
                                                </li>
                                                <li>
                                                    <a href="#">Secure Payment</a>
                                                </li>
                                                <li>
                                                    <a href="contact.html">Contact Us</a>
                                                </li>
                                                <li>
                                                    <a href="#">Sitemap</a>
                                                </li>
                                                <li>
                                                    <a href="#">Stores</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-2 col-sm-6 mb-sm-30px mb-lm-30px">
                                    <div className="single-wedge">
                                        <h4 className="footer-herading">CUSTOM LINKS</h4>
                                        <div className="footer-links">
                                            <ul>
                                                <li>
                                                    <a href="#">Legal Notice</a>
                                                </li>
                                                <li>
                                                    <a href="#">Prices Drop</a>
                                                </li>
                                                <li>
                                                    <a href="#">New Products</a>
                                                </li>
                                                <li>
                                                    <a href="#">Best Sales</a>
                                                </li>
                                                <li>
                                                    <a href="login.html">Login</a>
                                                </li>
                                                <li>
                                                    <a href="my-account.html">My Account</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 ">
                                    <div className="single-wedge">
                                        <h4 className="footer-herading">From Our Blog</h4>
                                        <div className="footer-blog-slider">
                                            <div className="footer-blog-slider-wrapper slider-nav-style-3 ">
                                                {/* Single-item */}
                                                <div className="single-slider-item">
                                                    <div className="footer-blog-post d-flex mb-30px">
                                                        <div className="footer-blog-post-top">
                                                            <div className="post-thumbnail">
                                                                <a href="blog-single-left-sidebar.html">
                                                                    <img
                                                                        src="../images/blog-image/blog-8.jpg"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="footer-blog-content">
                                                            <h4>
                                                                <a href="blog-single-left-sidebar.html">
                                                                    This is First Post For XipBlog
                                                                </a>
                                                            </h4>
                                                            <div className="footer-blog-meta">
                                                                <span className="autor">
                                                                    Posted by <a href="#">Demo Hasthemes</a>{" "}
                                                                </span>
                                                                <span className="date">Jun 29,2022</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="footer-blog-post">
                                                        <div className="footer-blog-post-top">
                                                            <div className="post-thumbnail">
                                                                <a href="blog-single-left-sidebar.html">
                                                                    <img
                                                                        src="../images/blog-image/blog-9.jpg"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="footer-blog-content">
                                                            <h4>
                                                                <a href="blog-single-left-sidebar.html">
                                                                    This is Secound Post For XipBlog
                                                                </a>
                                                            </h4>
                                                            <div className="footer-blog-meta">
                                                                <span className="autor">
                                                                    Posted by <a href="#">Demo Hasthemes</a>{" "}
                                                                </span>
                                                                <span className="date">Jun 29,2022</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Single-item */}
                                                <div className="single-slider-item">
                                                    <div className="footer-blog-post d-flex mb-30px">
                                                        <div className="footer-blog-post-top">
                                                            <div className="post-thumbnail">
                                                                <a href="blog-single-left-sidebar.html">
                                                                    <img
                                                                        src="../images/blog-image/blog-10.jpg"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="footer-blog-content">
                                                            <h4>
                                                                <a href="blog-single-left-sidebar.html">
                                                                    This is Third Post For XipBlog
                                                                </a>
                                                            </h4>
                                                            <div className="footer-blog-meta">
                                                                <span className="autor">
                                                                    Posted by <a href="#">Demo Hasthemes</a>{" "}
                                                                </span>
                                                                <span className="date">Jun 29,2022</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="footer-blog-post">
                                                        <div className="footer-blog-post-top">
                                                            <div className="post-thumbnail">
                                                                <a href="blog-single-left-sidebar.html">
                                                                    <img
                                                                        src="../images/blog-image/blog-11.jpg"
                                                                        alt=""
                                                                    />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="footer-blog-content">
                                                            <h4>
                                                                <a href="blog-single-left-sidebar.html">
                                                                    This is Fourth Post For XipBlog
                                                                </a>
                                                            </h4>
                                                            <div className="footer-blog-meta">
                                                                <span className="autor">
                                                                    Posted by <a href="#">Demo Hasthemes</a>{" "}
                                                                </span>
                                                                <span className="date">Jun 29,2022</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Single-item end */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="footer-paymet-warp d-flex">
                                        <div className="heading-info">Payment:</div>
                                        <div className="payment-way">
                                            <img
                                                className="payment-img img-responsive"
                                                src="../images/icons/payment.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="footer-social-icon d-flex">
                                        <div className="heading-info">Follow Us:</div>
                                        <div className="social-icon">
                                            <ul>
                                                <li className="facebook">
                                                    <a href="#">
                                                        <i className="ion-social-facebook" />
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="#">
                                                        <i className="ion-social-twitter" />
                                                    </a>
                                                </li>
                                                <li className="google">
                                                    <a href="#">
                                                        <i className="ion-social-google" />
                                                    </a>
                                                </li>
                                                <li className="youtube">
                                                    <a href="#">
                                                        <i className="ion-social-youtube" />
                                                    </a>
                                                </li>
                                                <li className="instagram">
                                                    <a href="#">
                                                        <i className="ion-social-instagram" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-tags">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tag-content">
                                        <ul>
                                            <li>
                                                <a href="#">Online Shopping</a>
                                            </li>
                                            <li>
                                                <a href="#">Promotions</a>
                                            </li>
                                            <li>
                                                <a href="#">My Orders</a>
                                            </li>
                                            <li>
                                                <a href="#">Help</a>
                                            </li>
                                            <li>
                                                <a href="#">Customer Service</a>
                                            </li>
                                            <li>
                                                <a href="#">Support</a>
                                            </li>
                                            <li>
                                                <a href="#">Most Populars</a>
                                            </li>
                                            <li>
                                                <a href="#">New Arrivals</a>
                                            </li>
                                            <li>
                                                <a href="#">Special Products</a>
                                            </li>
                                            <li>
                                                <a href="#">Manufacturers</a>
                                            </li>
                                            <li>
                                                <a href="#">Our Stores</a>
                                            </li>
                                            <li>
                                                <a href="#">Shipping</a>
                                            </li>
                                            <li>
                                                <a href="#">Payments</a>
                                            </li>
                                            <li>
                                                <a href="#">Warantee</a>
                                            </li>
                                            <li>
                                                <a href="#">Refunds</a>
                                            </li>
                                            <li>
                                                <a href="#">Checkout</a>
                                            </li>
                                            <li>
                                                <a href="#">Discount</a>
                                            </li>
                                            <li>
                                                <a href="#">Terms &amp; Conditions</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <p className="copy-text">
                                        Copyright © <a href="https://hasthemes.com/"> HasThemes</a>. All
                                        Rights Reserved
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer Area End */}
            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">x</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12 mb-lm-100px mb-sm-30px">
                                    <div className="quickview-wrapper">
                                        {/* slider */}
                                        <div className="gallery-top">
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/8.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/14.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/15.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/11.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/19.jpg"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className=" gallery-thumbs">
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/8.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/14.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/15.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/11.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="single-slide">
                                                <img
                                                    className="img-responsive m-auto"
                                                    src="../images/product-image/19.jpg"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="product-details-content quickview-content">
                                        <h2>Originals Kaval Windbr</h2>
                                        <p className="reference">
                                            Reference:<span> demo_17</span>
                                        </p>
                                        <div className="pro-details-rating-wrap">
                                            <div className="rating-product">
                                                <i className="ion-android-star" />
                                                <i className="ion-android-star" />
                                                <i className="ion-android-star" />
                                                <i className="ion-android-star" />
                                                <i className="ion-android-star" />
                                            </div>
                                            <span className="read-review">
                                                <a className="reviews" href="#">
                                                    Read reviews (1)
                                                </a>
                                            </span>
                                        </div>
                                        <div className="pricing-meta">
                                            <ul>
                                                <li className="old-price not-cut">€18.90</li>
                                            </ul>
                                        </div>
                                        <p className="quickview-para">
                                            Lorem ipsum dolor sit amet, consectetur adipisic elit eiusm
                                            tempor incidid ut labore et dolore magna aliqua. Ut enim ad
                                            minim venialo quis nostrud exercitation ullamco
                                        </p>
                                        <div className="pro-details-size-color">
                                            <div className="pro-details-color-wrap">
                                                <span>Color</span>
                                                <div className="pro-details-color-content">
                                                    <ul>
                                                        <li className="blue" />
                                                        <li className="maroon active" />
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pro-details-quality">
                                            <div className="cart-plus-minus">
                                                <input
                                                    className="cart-plus-minus-box"
                                                    type="text"
                                                    name="qtybutton"
                                                    defaultValue={1}
                                                />
                                            </div>
                                            <div className="pro-details-cart btn-hover">
                                                <a href="#"> + Add To Cart</a>
                                            </div>
                                        </div>
                                        <div className="pro-details-wish-com">
                                            <div className="pro-details-wishlist">
                                                <a href="wishlist.html">
                                                    <i className="ion-android-favorite-outline" />
                                                    Add to wishlist
                                                </a>
                                            </div>
                                            <div className="pro-details-compare">
                                                <a href="compare.html">
                                                    <i className="ion-ios-shuffle-strong" />
                                                    Add to compare
                                                </a>
                                            </div>
                                        </div>
                                        <div className="pro-details-social-info">
                                            <span>Share</span>
                                            <div className="social-info">
                                                <ul>
                                                    <li>
                                                        <a href="#">
                                                            <i className="ion-social-facebook" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="ion-social-twitter" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="ion-social-google" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="ion-social-instagram" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer