import React from 'react'

function Header() {
    return (
        <>
            <header className="header-wrapper">
                {/* Header Nav End */}
                <div className="header-top bg-white ptb-10px d-lg-block d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 d-flex">
                                <div className="logo align-self-center">
                                    <a href="index.html">
                                        <img
                                            className="img-responsive"
                                            src="../images/logo/logo.png"
                                            alt="logo.jpg"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-9 align-self-center">
                                <div className="header-right-element d-flex">
                                    <div className="search-element media-body mr-20px">
                                        <form className="d-flex" action="#">
                                            <div className="search-category">
                                                <select>
                                                    <option value={0}>All categories</option>
                                                    <option value={12}>Laptop</option>
                                                    <option value={13}>- - Hot Categories</option>
                                                    <option value={19}>- - - - Dresses</option>
                                                    <option value={20}>- - - - Jackets &amp; Coats</option>
                                                    <option value={21}>- - - - Sweaters</option>
                                                    <option value={22}>- - - - Jeans</option>
                                                    <option value={23}>- - - - Blouses &amp; Shirts</option>
                                                    <option value={14}>- - Outerwear &amp; Jackets</option>
                                                    <option value={24}>- - - - Basic Jackets</option>
                                                    <option value={25}>- - - - Real Fur</option>
                                                    <option value={26}>- - - - Down Coats</option>
                                                    <option value={27}>- - - - Blazers</option>
                                                    <option value={28}>- - - - Trench Coats</option>
                                                    <option value={15}>- - Weddings &amp; Events</option>
                                                    <option value={29}>- - - - Wedding Dresses</option>
                                                    <option value={30}>- - - - Evening Dresses</option>
                                                    <option value={31}>- - - - Prom Dresses</option>
                                                    <option value={32}>- - - - Bridesmaid Dresses</option>
                                                    <option value={33}>- - - - Wedding Accessories</option>
                                                    <option value={16}>- - Bottoms</option>
                                                    <option value={34}>- - - - Skirts</option>
                                                    <option value={35}>- - - - Leggings</option>
                                                    <option value={36}>- - - - Pants &amp; Capris</option>
                                                    <option value={37}>- - - - Wide Leg Pants</option>
                                                    <option value={38}>- - - - Shorts</option>
                                                    <option value={17}>- - Tops &amp; Sets</option>
                                                    <option value={39}>- - - - Tank Tops</option>
                                                    <option value={40}>- - - - Suits &amp; Sets</option>
                                                    <option value={41}>- - - - Jumpsuits</option>
                                                    <option value={42}>- - - - Rompers</option>
                                                    <option value={43}>- - - - Sleep &amp; Lounge</option>
                                                    <option value={18}>- - Accessories</option>
                                                    <option value={44}>
                                                        - - - - Eyewear &amp; Accessories
                                                    </option>
                                                    <option value={45}>- - - - Hats &amp; Caps</option>
                                                    <option value={46}>
                                                        - - - - Belts &amp; Cummerbunds
                                                    </option>
                                                    <option value={47}>- - - - Scarves &amp; Wraps</option>
                                                    <option value={48}>- - - - Gloves &amp; Mittens</option>
                                                    <option value={49}>Computer</option>
                                                    <option value={50}>- - Bottoms</option>
                                                    <option value={53}>- - - - Skirts</option>
                                                    <option value={54}>- - - - Leggings</option>
                                                    <option value={55}>- - - - Jeans</option>
                                                    <option value={56}>- - - - Pants &amp; Capris</option>
                                                    <option value={57}>- - - - Shorts</option>
                                                    <option value={51}>- - Outerwear &amp; Jackets</option>
                                                    <option value={58}>- - - - Trench</option>
                                                    <option value={59}>- - - - Genuine Leather</option>
                                                    <option value={60}>- - - - Parkas</option>
                                                    <option value={61}>- - - - Down Jackets</option>
                                                    <option value={62}>- - - - Wool &amp; Blends</option>
                                                    <option value={52}>- - Underwear &amp; Loungewear</option>
                                                    <option value={63}>- - - - Boxers</option>
                                                    <option value={64}>- - - - Briefs</option>
                                                    <option value={65}>- - - - Long Johns</option>
                                                    <option value={66}>
                                                        - - - - Men's Sleep &amp; Lounge
                                                    </option>
                                                    <option value={67}>- - - - Pajama Sets</option>
                                                    <option value={68}>Smartphone</option>
                                                    <option value={69}>- - Accessories &amp; Parts</option>
                                                    <option value={75}>- - - - Cables &amp; Adapters</option>
                                                    <option value={76}>- - - - Batteries</option>
                                                    <option value={77}>- - - - Chargers</option>
                                                    <option value={78}>- - - - Bags &amp; Cases</option>
                                                    <option value={79}>- - - - Electronic Cigarettes</option>
                                                    <option value={70}>- - Audio &amp; Video</option>
                                                    <option value={80}>- - - - Televisions</option>
                                                    <option value={81}>- - - - TV Receivers</option>
                                                    <option value={82}>- - - - Projectors</option>
                                                    <option value={83}>- - - - Audio Amplifier Boards</option>
                                                    <option value={84}>- - - - TV Sticks</option>
                                                    <option value={71}>- - Camera &amp; Photo</option>
                                                    <option value={85}>- - - - Digital Cameras</option>
                                                    <option value={86}>- - - - Camcorders</option>
                                                    <option value={87}>- - - - Camera Drones</option>
                                                    <option value={88}>- - - - Action Cameras</option>
                                                    <option value={89}>- - - - Photo Studio Supplies</option>
                                                    <option value={72}>- - Portable Audio &amp; Video</option>
                                                    <option value={90}>- - - - Headphones</option>
                                                    <option value={91}>- - - - Speakers</option>
                                                    <option value={92}>- - - - MP3 Players</option>
                                                    <option value={93}>- - - - VR/AR Devices</option>
                                                    <option value={94}>- - - - Microphones</option>
                                                    <option value={73}>- - Smart Electronics</option>
                                                    <option value={95}>- - - - Wearable Devices</option>
                                                    <option value={96}>- - - - Smart Home Appliances</option>
                                                    <option value={97}>- - - - Smart Remote Controls</option>
                                                    <option value={98}>- - - - Smart Watches</option>
                                                    <option value={99}>- - - - Smart Wristbands</option>
                                                    <option value={74}>- - Video Games</option>
                                                    <option value={100}>- - - - Handheld Game Players</option>
                                                    <option value={101}>- - - - Game Controllers</option>
                                                    <option value={102}>- - - - Joysticks</option>
                                                    <option value={103}>- - - - Stickers</option>
                                                    <option value={104}>Game Consoles</option>
                                                    <option value={105}>Mp3 &amp; headphone</option>
                                                    <option value={106}>Tv &amp; Video</option>
                                                    <option value={107}>Watches</option>
                                                    <option value={108}>Washing Machine</option>
                                                    <option value={109}>Camera</option>
                                                    <option value={110}>Audio &amp; Theater</option>
                                                    <option value={111}>Accessories</option>
                                                    <option value={112}>Games &amp; Consoles</option>
                                                </select>
                                            </div>
                                            <input type="text" placeholder="Enter your search key ... " />
                                            <button>Search</button>
                                        </form>
                                    </div>
                                    {/*Cart info Start */}
                                    <div className="header-tools d-flex">
                                        <div className="cart-info d-flex align-self-center">
                                            <a
                                                href="#offcanvas-wishlist"
                                                className="heart offcanvas-toggle"
                                            >
                                                <i className="lnr lnr-heart" />
                                                <span>Wishlist</span>
                                            </a>
                                            <a href="#offcanvas-cart" className="bag offcanvas-toggle">
                                                <i className="lnr lnr-cart" />
                                                <span>My Cart</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {/*Cart info End */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Header Nav End */}
                <div className="header-menu bg-white sticky-nav d-lg-block d-none padding-0px">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="header-menu-vertical">
                                    <h4 className="menu-title">Browse Categories </h4>
                                    <ul className="menu-content display-none">
                                        <li className="menu-item">
                                            <a href="#">Televisions</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">
                                                Electronics <i className="ion-ios-arrow-right" />
                                            </a>
                                            <ul className="sub-menu flex-wrap">
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            {" "}
                                                            <strong> Accessories &amp; Parts</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Cables &amp; Adapters</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Batteries</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Chargers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Bags &amp; Cases</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Electronic Cigarettes</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            <strong>Camera &amp; Photo</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Digital Cameras</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Camcorders</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Camera Drones</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Action Cameras</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Photo Studio Supplie</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            <strong>Smart Electronics</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Wearable Devices</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Home Appliances</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Remote Controls</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Watches</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Smart Wristbands</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <span>
                                                            <strong>Audio &amp; Video</strong>
                                                        </span>
                                                    </a>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Televisions</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">TV Receivers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Projectors</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Audio Amplifier Boards</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">TV Sticks</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            {/* sub menu */}
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">
                                                Video Games <i className="ion-ios-arrow-right" />
                                            </a>
                                            <ul className="sub-menu sub-menu-2">
                                                <li>
                                                    <ul className="submenu-item">
                                                        <li>
                                                            <a href="#">Handheld Game Players</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Game Controllers</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Joysticks</a>
                                                        </li>
                                                        <li>
                                                            <a href="#">Stickers</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            {/* sub menu */}
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">Digital Cameras</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#">Headphones</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Wearable Devices</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Smart Watches</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Game Controllers</a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#"> Smart Home Appliances</a>
                                        </li>
                                    </ul>
                                    {/* menu content */}
                                </div>
                                {/* header menu vertical */}
                            </div>
                            <div className="col-lg-6">
                                <div className="header-horizontal-menu">
                                    <ul className="menu-content">
                                        <li className="active menu-dropdown">
                                            <a href="#">
                                                Home <i className="ion-ios-arrow-down" />
                                            </a>
                                            <ul className="main-sub-menu">
                                                <li>
                                                    <a href="index.html">Home 1</a>
                                                </li>
                                                <li>
                                                    <a href="index-2.html">Home 2</a>
                                                </li>
                                                <li>
                                                    <a href="index-3.html">Home 3</a>
                                                </li>
                                                <li>
                                                    <a href="index-4.html">Home 4</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-dropdown">
                                            <a href="#">
                                                Shop <i className="ion-ios-arrow-down" />
                                            </a>
                                            <ul className="mega-menu-wrap">
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop Grid</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-3-column.html">Shop Grid 3 Column</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-4-column.html">Shop Grid 4 Column</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-left-sidebar.html">
                                                                Shop Grid Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-right-sidebar.html">
                                                                Shop Grid Right Sidebar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop List</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-list.html">Shop List</a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-list-left-sidebar.html">
                                                                Shop List Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="shop-list-right-sidebar.html">
                                                                Shop List Right Sidebar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop Single</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product.html">Shop Single</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-variable.html">
                                                                Shop Variable
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-affiliate.html">
                                                                Shop Affiliate
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-group.html">Shop Group</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-tabstyle-2.html">
                                                                Shop Tab 2
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-tabstyle-3.html">
                                                                Shop Tab 3
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <ul>
                                                        <li className="mega-menu-title">
                                                            <a href="#">Shop Single</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-slider.html">Shop Slider</a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-gallery-left.html">
                                                                Shop Gallery Left
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-gallery-right.html">
                                                                Shop Gallery Right
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-sticky-left.html">
                                                                Shop Sticky Left
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="single-product-sticky-right.html">
                                                                Shop Sticky Right
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="w-100">
                                                    <ul className="banner-megamenu-wrapper d-flex">
                                                        <li className="banner-wrapper mr-30px">
                                                            <a href="single-product.html">
                                                                <img
                                                                    src="../images/menu-image/banner-menu2.jpg"
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </li>
                                                        <li className="banner-wrapper">
                                                            <a href="single-product.html">
                                                                <img
                                                                    src="../images/menu-image/banner-menu3.jpg"
                                                                    alt=""
                                                                />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-dropdown">
                                            <a href="#">
                                                Pages <i className="ion-ios-arrow-down" />
                                            </a>
                                            <ul className="main-sub-menu">
                                                <li>
                                                    <a href="about.html">About Page</a>
                                                </li>
                                                <li>
                                                    <a href="cart.html">Cart Page</a>
                                                </li>
                                                <li>
                                                    <a href="checkout.html">Checkout Page</a>
                                                </li>
                                                <li>
                                                    <a href="compare.html">Compare Page</a>
                                                </li>
                                                <li>
                                                    <a href="login.html">Login &amp; Register Page</a>
                                                </li>
                                                <li>
                                                    <a href="my-account.html">Account Page</a>
                                                </li>
                                                <li>
                                                    <a href="empty-cart.html">Empty Cart Page</a>
                                                </li>
                                                <li>
                                                    <a href="404.html">404 Page</a>
                                                </li>
                                                <li>
                                                    <a href="wishlist.html">Wishlist Page</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-dropdown">
                                            <a href="#">
                                                Blog <i className="ion-ios-arrow-down" />
                                            </a>
                                            <ul className="main-sub-menu">
                                                <li className="menu-dropdown position-static">
                                                    <a href="#">
                                                        Blog Grid <i className="ion-ios-arrow-right" />
                                                    </a>
                                                    <ul className="main-sub-menu main-sub-menu-2">
                                                        <li>
                                                            <a href="blog-grid-left-sidebar.html">
                                                                Blog Grid Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="blog-grid-right-sidebar.html">
                                                                Blog Grid Right Sidebar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="menu-dropdown position-static">
                                                    <a href="#">
                                                        Blog List <i className="ion-ios-arrow-right" />
                                                    </a>
                                                    <ul className="main-sub-menu main-sub-menu-2">
                                                        <li>
                                                            <a href="blog-list-left-sidebar.html">
                                                                Blog List Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="blog-list-right-sidebar.html">
                                                                Blog List Right Sidebar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="menu-dropdown position-static">
                                                    <a href="#">
                                                        Blog Single <i className="ion-ios-arrow-right" />
                                                    </a>
                                                    <ul className="main-sub-menu main-sub-menu-2">
                                                        <li>
                                                            <a href="blog-single-left-sidebar.html">
                                                                Blog Single Left Sidebar
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="blog-single-right-sidebar.html">
                                                                Blog Single Right Sidbar
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-dropdown">
                                            <a href="#">
                                                Custom Menu <i className="ion-ios-arrow-down" />
                                            </a>
                                            <ul className="mega-menu-wrap mega-menu-wrap-2">
                                                <li>
                                                    <div className="custom-single-item">
                                                        <h4>
                                                            <a href="shop-4-column.html">
                                                                Women Is Clothes &amp; Fashion
                                                            </a>
                                                        </h4>
                                                        <p>
                                                            Shop Women Is Clothing And Accessories And Get
                                                            Inspired By The Latest Fashion Trends.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-single-item">
                                                        <h4>
                                                            <a href="shop-4-column.html">Simple Style</a>
                                                        </h4>
                                                        <p>
                                                            A New Flattering Style With All The Comfort Of Our
                                                            Linen.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="custom-single-item">
                                                        <h4>
                                                            <a href="shop-4-column.html">Easy Style</a>
                                                        </h4>
                                                        <p>
                                                            Endless Styling Possibilities In A Collection Full Of
                                                            Versatile Pieces.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="contact.html">contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="header-tools header-tools-sticky d-none d-flex h-100 justify-content-center">
                                    <div className="cart-info d-flex align-self-center">
                                        <a
                                            href="#offcanvas-wishlist"
                                            className="heart offcanvas-toggle"
                                        >
                                            <i className="lnr lnr-heart" />
                                            <span>Wishlist</span>
                                        </a>
                                        <a href="#offcanvas-cart" className="bag offcanvas-toggle">
                                            <i className="lnr lnr-cart" />
                                            <span>My Cart</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* row */}
                    </div>
                    {/* container */}
                </div>
                {/* header menu */}
            </header>
        </>
    )
}

export default Header