import React, { useState } from 'react'


function Login() {

    const [userDetails, setUserDetails] = useState()
    const [credentials, setCredentials] = useState({
        email: "",
        password: ""
    })

    async function fetchUser() {
        const response = await fetch(`${process.env.REACT_APP_URL}account/login/log_in`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                'email': credentials.email,
                'password': credentials.password,
            })
        })
        return response.json()

    }

    function submitLogin() {
        fetchUser().then((userData)=>{
            console.log(userData)
        }).catch(()=>{
            console.warn("something went wrong")
        })
    }

    return (
        <>
            <div class="breadcrumb-area">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="breadcrumb-content">
                                <ul class="nav">
                                    <li><a href="index.html">Home</a></li>
                                    <li>Login / Register</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login-register-area mb-50px mt-40px">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 ms-auto me-auto">
                            <div class="login-register-wrapper">
                                <div class="login-register-tab-list nav">
                                    <a class="active" data-bs-toggle="tab" href="#lg1">
                                        <h4>login</h4>
                                    </a>
                                    <a data-bs-toggle="tab" href="#lg2">
                                        <h4>register</h4>
                                    </a>
                                </div>
                                <div class="tab-content">
                                    <div id="lg1" class="tab-pane active">
                                        <div class="login-form-container">
                                            <div class="login-register-form">
                                                <input type="text" name="user-name" value={credentials.email} onChange={(e) => { setCredentials({ ...credentials, email: e.target.value }) }} placeholder="Username" />
                                                <input type="password" name="user-password" value={credentials.password} onChange={(e) => { setCredentials({ ...credentials, password: e.target.value }) }} placeholder="Password" />
                                                <div class="button-box">
                                                    <div class="login-toggle-btn">
                                                        <input type="checkbox" />
                                                        <a class="flote-none" href="javascript:void(0)">Remember me</a>
                                                        <a href="#">Forgot Password?</a>
                                                    </div>
                                                    <button type="submit" onClick={submitLogin}><span>Login</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login